.transaction-list-container {
  padding: 20px;
}

.transaction-header {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.search-bar-container {
  margin-bottom: 15px;
}
.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.search-icon {
  font-size: 1.2rem;
  margin-right: 10px;
  color: #333;
}

.search-input {
  width: 100%;
  border: none;
  outline: none;
  padding: 8px 12px;
  font-size: 1rem;
  background-color: transparent;
}

.transaction-list {
  margin-bottom: 20px;
}

.transaction-item {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.transaction-item:hover {
  background-color: #f9f9f9;
}

.transaction-id {
  font-weight: bold;
  font-size: 18px;
}

.transaction-date {
  font-size: 14px;
  color: #777;
}

.transaction-charges {
  color: green;
  font-size: 16px;
}

.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
  margin: 20px 0;
}

.pagination-button {
  width: 120px;
  background-color: #007bff;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.pagination-button[disabled] {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination-button:hover:not([disabled]) {
  background-color: #0056b3;
}

.total-charges-container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.total-charges {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.total-charges-summary {
  margin-bottom: 10px;
}

.clear-transactions-button {
  background-color: #ff4d4d;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.clear-transactions-button:hover {
  background-color: #cc0000;
}

.transaction-status {
  font-weight: bold;
  color: #ff8c00;
}

.transaction-free {
  color: #4caf50;
}

.transaction-paid {
  color: #f44336;
}

.transaction-energy {
  font-size: 16px;
  color: #333;
}

.transaction-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.transaction-footer-left {
  display: flex;
  flex-direction: column;
}

.transaction-footer-right {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.transaction-label {
  font-size: 14px;
  color: #555;
}

.transaction-value {
  font-size: 16px;
  font-weight: bold;
}
.clear-history {
  font-size: 1rem;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  min-width: 120px;
  border: none;
  cursor: pointer;
}

.clear-history .pi-trash {
  font-size: 1rem;
}

.clear-history:hover {
  background-color: #0056b3;
}

@media (max-width: 445px) {
  .clear-history {
    font-size: 0.75rem;
    padding: 6px 10px;
    min-width: 90px;
  }

  .clear-history .pi-trash {
    font-size: 0.75rem;
  }
}
@media (max-width: 400px) {
  .pagination-controls {
    justify-content: center;
    gap: 10px;
    margin: 0 20px;
    margin: 20px 0;
  }

  .pagination-controls > * {
    flex: none;
    max-width: 100px;
  }
  .pagination-button {
    margin: 0 5px;
  }
  .pagination-text {
    margin: 0 10px;
    text-align: center;
  }
}
