.container {
  margin: 20px;
}

.qr-button {
  margin-right: 10px;
  background-color: green;
  border-color: green;
  color: white;
}

.qr-button:hover {
  background-color: darkgreen;
  border-color: darkgreen;
}

.qr-button:focus {
  outline: none;
  box-shadow: none;
}

.qr-button-download {
  background-color: green;
  border-color: green;
  color: white;
}

.qr-button-download:hover {
  background-color: darkgreen;
  border-color: darkgreen;
}

.qr-button-download:focus {
  outline: none;
  box-shadow: none;
}

.qr-container {
  margin-top: 10px;
  margin-bottom: 20px;
}

.qr-code {
  margin-top: 20px;
  text-align: center;
}
@media (max-width: 440px) {
  .qr-container {
    flex-direction: column;
  }

  .qr-button,
  .qr-button-download {
    margin: 15px 0;
  }
}
