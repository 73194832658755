.details-switch {
  transition: background-color 0.3s ease;
}

.details-switch.switch-active .p-inputswitch-slider {
  background-color: green !important;
}

.details-switch.switch-active .p-inputswitch-slider::before {
  background-color: white !important;
}
