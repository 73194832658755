.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.icon {
  background-color: green;
  color: white;
}

.add-owner-button {
  margin: 20px;
  background-color: green;
  border-color: black;
  width: 200px;
}

.button-width {
  width: 200px;
}

.p-button-raised:active {
  background-color: greenyellow;
  color: black;
  border-color: transparent;
  box-shadow: none;
}

.p-button-raised:focus {
  border-color: transparent;
  box-shadow: none;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.loading-container p {
  margin-top: 20px;
  font-size: 1.2rem;
  color: #333;
}

.p-button-danger {
  background-color: red !important;
  border: none !important;
}

@media only screen and (max-width: 425px) {
  .p-inputtext {
    max-width: fit-content;
    box-sizing: border-box;
  }
}
