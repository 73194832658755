.bank-accounts-list {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.p-datatable-wrapper {
  width: 100%;
  overflow: hidden;
}

.p-datatable-table {
  table-layout: fixed;
  width: 100%;
}

.bank-accounts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.bank-accounts-item {
  display: flex;
  flex-direction: column;
}

.bank-accounts-label {
  font-size: 14px;
  margin-bottom: 5px;
  color: #555;
}

.bank-accounts-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.bank-accounts-button-wrapper {
  margin-top: 20px;
  text-align: center;
}

.station-dropdown-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.station-dropdown-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.bank-accounts-loading-indicator {
  margin: 1rem 0;
  font-size: 1.2rem;
  font-weight: bold;
  color: #007ad9;
  text-align: center;
}
.bank-accounts-card {
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  background: #f8f9fa;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  margin-top: 1.5rem;
  overflow: hidden;
}

@media (max-width: 768px) {
  .p-datatable-wrapper {
    overflow-x: auto;
    display: block;
  }
}

.p-datatable-table {
  width: 100%;
  min-width: 600px;
  table-layout: auto;
}

.p-datatable th {
  white-space: nowrap;
}

.p-datatable td {
  word-break: break-word;
  padding: 8px;
}
