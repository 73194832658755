.evse-list {
  margin: 1em 0;
}

.evse-item {
  margin-bottom: 1.5em;
}

.connector-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.connector-item {
  flex: 1;
  margin: 10px 0;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.capabilities-section {
  margin-top: 1em;
  padding: 10px;
}

.capabilities-section ul {
  padding-left: 1.5em;
}

.capabilities-section li {
  margin-bottom: 0.5em;
  font-size: 0.95em;
  line-height: 1.2em;
  word-wrap: break-word;
}

.section-title {
  font-weight: bold;
  font-size: 1.2em;
  color: #333;
}

@media (max-width: 768px) {
  .connector-list {
    flex-direction: column;
  }

  .connector-item {
    width: 100%;
    padding: 8px;
  }

  .capabilities-section li {
    font-size: 0.9em;
  }
}

@media (max-width: 600px) {
  .connector-list {
    flex-direction: column;
  }

  .connector-item {
    width: 100%;
  }

  .capabilities-section li {
    font-size: 0.85em;
  }
}
