.clearing-list-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 900px;
  margin: auto;
}

.card {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.card .p-button {
  flex: 1;
  min-width: 150px;
  max-width: 300px;
  text-align: center;
}

.loading-message,
.error-message,
.no-data-message {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
}

.loading-message {
  color: #007bff;
}

.error-message {
  color: #d9534f;
}

.no-data-message {
  color: #5a5a5a;
}

.table-wrapper {
  overflow-x: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px 15px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
  font-weight: bold;
  border-bottom: 2px solid #e0e0e0;
}

td {
  border-bottom: 1px solid #e0e0e0;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

.clickable-row {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.clickable-row:hover {
  background-color: #f1f1f1;
}
