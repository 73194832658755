.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5%;
  height: fit-content;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-card-width {
  width: 80%;
  max-width: 1200px;
  margin: auto;
}

.input-group-margin {
  margin-bottom: 1.5rem;
}

.input-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.save-button {
  width: 20%;
  background-color: green;
  border-color: black;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.p-button-raised:active {
  background-color: greenyellow;
  color: black;
  border-color: transparent;
  box-shadow: none;
}

.p-button-raised:focus {
  border-color: transparent;
  box-shadow: none;
}

.error-message-container {
  margin-top: 0.5rem;
}

@media only screen and (max-width: 510px) {
  .save-button {
    width: 150px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 425px) {
  .p-inputtext {
    max-width: fit-content;
    box-sizing: border-box;
  }

  .flex.flex-column.md\\:flex-row.gap-3 {
    flex-direction: column !important;
    gap: 1rem;
  }

  .button-container {
    flex-direction: column;
  }

  .save-button {
    width: 100%;
    font-size: 16px;
  }
}
