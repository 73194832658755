.app-container {
  display: flex;
  flex-direction: column;
}

.menubar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

@media (max-width: 768px) {
  .app-container {
    flex-direction: column;
  }

  .main-content {
    padding-top: 60px;
    margin: 0;
    width: 100%;
    padding: 0 10px;
  }
}
