.sticky-actions {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 1em;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-around;
  z-index: 1;
  gap: 10px;
}

.sticky-actions .p-button {
  width: 30%;
  font-size: 1em;
}

@media (max-width: 768px) {
  .sticky-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 10px;
  }

  .sticky-actions .p-button {
    font-size: 0.85em;
    padding: 0.6em;
    width: 32%;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .sticky-actions {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }

  .sticky-actions .p-button {
    font-size: 0.85em;
    padding: 0.75em;
    width: 100%;
    min-width: auto;
    text-align: center;
  }
}
