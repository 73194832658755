.station-details-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  margin: 0;
  background-color: white;
  min-height: 100vh;
}

.actions-section {
  display: flex;
  justify-content: space-around;
  margin-top: 2em;
}

.wide-card {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5em;
}

.station-name {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  margin-bottom: 1em;
  word-wrap: break-word;
}

@media (max-width: 768px) {
  .station-name {
    font-size: 1em;
    text-align: center;
  }
  .wide-card {
    padding: 1em;
  }
}

@media (max-width: 600px) {
  .station-name {
    font-size: 0.85em;
    text-align: center;
    margin-bottom: 0.5em;
  }
  .wide-card {
    padding: 0.5em;
  }
}

@media (max-width: 400px) {
  .station-name {
    font-size: 0.7em;
    text-align: center;
    margin-bottom: 0.5em;
  }
  .wide-card {
    padding: 0.3em;
  }
}
