.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.card {
  width: 100%;
  padding: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 8px;
}

.p-datatable {
  width: 100%;
}

.p-column-filter {
  min-width: 12rem;
}

.p-datatable-header {
  font-size: 1rem;
}

@media (max-width: 376px) {
  .hide-on-small {
    display: none;
  }
  .p-column-filter-menu-button {
    display: none;
  }
  .responsive-dropdown .p-dropdown-trigger {
    display: none;
  }
}

@media (max-width: 480px) {
  .responsive-dropdown {
    min-width: "50px";
  }
}

@media (min-width: 1024px) {
  .responsive-dropdown {
    min-width: "150px";
  }
}

@media screen and (max-width: 768px) {
  .card {
    padding: 1rem;
  }
  .responsive-dropdown {
    min-width: "120px";
  }
  .p-datatable {
    font-size: 0.85rem;
  }

  .p-column-filter {
    min-width: 8rem;
  }

  .p-datatable-header {
    font-size: 0.9rem;
  }

  .p-inputtext,
  .p-input-icon-left .p-inputtext {
    width: 100%;
    font-size: 0.85rem;
    padding-left: 2rem;
  }

  .p-input-icon-left .pi {
    font-size: 0.85rem;
    left: 8px;
  }

  .p-dropdown {
    font-size: 0.85rem;
    width: 100%;
    max-width: 150px;
  }
}

@media screen and (max-width: 425px) {
  .card {
    padding: 0.5rem;
    overflow-x: hidden;
  }

  .p-datatable {
    font-size: 0.7rem;
  }

  .p-datatable-tbody > tr > td {
    padding: 0.3rem;
  }

  .p-column-filter {
    min-width: 7rem;
  }

  .p-datatable-header {
    font-size: 0.8rem;
  }

  .p-inputtext,
  .p-input-icon-left .p-inputtext {
    width: 100%;
    max-width: 80px;
    padding-left: 25px;
  }

  .p-input-icon-left .pi {
    font-size: 0.8rem;
    left: 5px;
  }

  .p-dropdown {
    max-width: 100px;
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 375px) {
  .p-inputtext,
  .p-input-icon-left .p-inputtext {
    max-width: 70px;
    padding-left: 20px;
  }

  .p-column-filter {
    min-width: 2rem;
    max-width: 2rem;
  }

  .p-datatable {
    font-size: 0.65rem;
  }

  .p-dropdown {
    max-width: 90px;
    font-size: 0.7rem;
  }

  .p-input-icon-left .pi {
    font-size: 0.7rem;
    left: 5px;
  }
}

@media screen and (max-width: 325px) {
  .p-inputtext,
  .p-input-icon-left .p-inputtext {
    max-width: 65px;
  }

  .p-column-filter {
    min-width: 5rem;
  }

  .p-datatable {
    font-size: 0.6rem;
  }

  .p-dropdown {
    max-width: 80px;
    font-size: 0.6rem;
  }

  .p-input-icon-left .pi {
    font-size: 0.6rem;
  }
}
