.opening-times-section {
  margin: 1em 0;
}

.opening-times-section ul {
  padding-left: 1.5em;
}

.opening-times-section li {
  margin-bottom: 0.5em;
  font-size: 1em;
  line-height: 1.4em;
}

.section-title {
  font-weight: bold;
  font-size: 1.2em;
  color: #333;
}

/* Media query for screens smaller than 768px */
@media (max-width: 768px) {
  .opening-times-section li {
    font-size: 0.85em; /* Smaller font size for smaller screens */
    line-height: 1.2em; /* Adjust line spacing */
  }
}
