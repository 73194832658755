.clearing-details {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.details-card {
  padding: 20px;
  border-radius: 8px;
  background: #f8f9fa;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  margin-top: 1.5rem;
}

.details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.details-item {
  display: flex;
  flex-direction: column;
}

.details-label {
  font-size: 14px;
  margin-bottom: 5px;
  color: #555;
}

.details-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.update-button {
  margin-top: 20px;
  text-align: center;
}
.station-dropdown {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.dropdown-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.month-picker-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.loading-indicator {
  margin: 1rem 0;
  font-size: 1.2rem;
  font-weight: bold;
  color: #007ad9;
  text-align: center;
}
