.map-and-address {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 1em;
}

.map-container-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
}

.map-container {
  width: 100%;
  height: 200px;
  cursor: pointer;
  transition: width 0.3s ease, height 0.3s ease;
}

.map-container.expanded {
  height: 400px;
}

.zoom-hint {
  margin-top: 0.5em;
  font-size: 0.85em;
  color: #666;
  text-align: center;
}

.info-section {
  flex: 1;
  padding-left: 10px;
}

.section-title {
  font-weight: bold;
  font-size: 1.2em;
  color: #333;
}

@media (max-width: 768px) {
  .map-and-address {
    flex-direction: column;
    align-items: stretch;
  }

  .map-container-wrapper {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .map-container {
    width: 100%;
    height: 200px;
  }
}
